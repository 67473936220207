.uc {
  padding: 10px;
  font-weight: bold;
  background-color: #cccccc;
  text-transform: uppercase;
  color: #a1a1a1;
  text-align: center;
}
.muc {
  padding: 10px;
  font-weight: bold;
  background-color: #c50f7f;
  text-transform: uppercase;
  color: black;
  text-align: center;
}
