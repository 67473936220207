@import url('https://use.typekit.net/rux8ogk.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');
@import url('https://use.typekit.net/gls6amp.css');

* {
	// font-family: myriad-pro, sans-serif !important;
	margin: 0;
}

html {
	scroll-behavior: smooth;
}
// MIGHT BE A FIX FOR LARGE REGISTERED SYMBOLS IN HEADERS
h2 {
	font-family: myriad-pro, sans-serif !important;
	font-weight: 500 !important;
}

:focus {
	outline: none;
}

body {
	overflow-x: hidden;
}

a {
	text-decoration: none;
	color: inherit;
}

.route-wrapper {
	position: relative;
}

.route-wrapper > div {
	position: absolute;
	width: 100%;
}

.pastel-colour-1 {
	background-color: #af93c5 !important;
	fill: #af93c5 !important;
}
.pastel-colour-2 {
	background-color: #8dcd9e !important;
}
.pastel-colour-3 {
	background-color: #fbb279 !important;
}
.pastel-colour-4 {
	background-color: #71cde0 !important;
}

.Pregnancy {
	background-color: #c50f7f !important;
}

.Hormonal {
	background-color: #df8ca9 !important;
}

.Magnesium {
	background-color: #b4687c !important;
}

.Kids .Health {
	background-color: #f05662 !important;
}

.CardioMetabolic {
	background-color: #b80c4b !important;
}

.Stress {
	background-color: #ba7bb1 !important;
}

.Essential .Fatty .Acids {
	background-color: #007db6 !important;
}

.Energy {
	background-color: #5673b8 !important;
}

.Fatigue {
	background-color: #5673b8 !important;
}

.Inflammation {
	background-color: #1192d1 !important;
}

.Wellness {
	background-color: #6cb33f !important;
}

.Digestion {
	background-color: #26bcb4 !important;
}

.Detoxification {
	background-color: #3daf8d !important;
}

.Multivitamins .And .Minerals {
	background-color: #84c778 !important;
}

.Body.Composition {
	background-color: #b4d88b !important;
}

.Best.Practice {
	background-color: #3192c7;
}
.MetaScan {
	background-color: #00aec7;
}
.Probiotics {
	background-color: #f7be4e !important;
}

.Allergies {
	background-color: #f99d2a !important;
}

.Immunity {
	background-color: #f26a36 !important;
}

.Musculoskeletal {
	background-color: #009cc2 !important;
}

.Clinical {
	background-color: #71cde0 !important;
}
.congress {
	background-color: #af93c5 !important;
}

.webinar {
	background-color: #8dcd9e !important;
}

.seminar {
	background-color: #ddccff !important;
}

.protocol {
	background-color: #71cde0 !important;
}

.button-main {
	color: #fff !important;
	font-weight: 600 !important;
	box-shadow: 2px 2px 5px 0px rgba(107, 152, 217, 0.5) !important;
	transition: all 0.3s 0s ease-in-out !important;
	border-radius: 16px !important;
}

.button-main:hover {
	box-shadow: 8px 8px 19px 0px rgba(107, 152, 217, 0.7) !important;
	transform: scale(1.02);
}

.featureddynamicshadow {
	box-shadow: 3px 3px 6px -1px rgba(0, 111, 255, 0.3),
		1px 1px 3px 0px rgba(0, 111, 255, 0.1),
		-2px -2px 5px 0px rgba(43, 54, 68, 0.4) !important;
}

.promotionalcard {
	transition: all 0.4s ease;
	box-shadow: 3px 3px 6px -1px rgba(0, 111, 255, 0.03),
		1px 1px 3px 0px rgba(0, 111, 255, 0.01),
		-2px -2px 5px 0px rgba(0, 111, 255, 0.04);
	transform: scale(1);
}

.promotionalcard:hover {
	transition: all 0.4s ease;
	box-shadow: 8px 8px 17px 2px rgba(0, 111, 255, 0.15),
		2px 2px 5px 0px rgba(0, 111, 255, 0.07),
		-4px -4px 5px 0px rgba(0, 111, 255, 0.03) !important;
	transform: scale(1.01);
}

.MuiInputBase-root:before {
	border-bottom: none !important;
}
.MuiInputBase-root:after {
	border-bottom: none !important;
}
.MuiFormLabel-root.MuiInputLabel-root {
	color: #6b98d9;
	margin-left: 16px;
	line-height: 0.2;
}

.MuiFilledInput-underline {
	background-color: #fff !important;
	border-radius: 16px !important;
	color: #002a54 !important;
	border: 2px solid #6b98d9;
}

.MuiFormLabel-root.Mui-focused {
	padding-top: 8px;
}

.MuiInputBase-input {
	padding: 8px 0 7px !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
	margin-top: 12px;
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
	min-height: 42px;
	.MuiInputBase-input.MuiFilledInput-input {
		padding-left: 26px !important;
		border-radius: 16px;
	}
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled.Mui-focused.Mui-focused {
	margin-top: 4px;
	color: #6b98d9;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
	margin-left: 12px;
	color: #6b98d9;
	background-color: transparent;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 {
	margin-left: 135px;
}

.MuiSvgIcon-root.MuiSelect-icon {
	color: #6b98d9;
	top: 0 !important;
}

#search-term-label {
	color: #002a54 !important;
}

.item-boxshadow {
	box-shadow: 2px 2px 5px 0px rgba(79, 98, 124, 0.15) !important;
	transition: all 0.3s 0s ease-in-out !important;
}

.item-boxshadow:hover {
	box-shadow: 3px 3px 7px 0px rgba(107, 152, 217, 0.1) !important;
}

.scroll-menu-arrow {
	z-index: 1;
	width: 0;
}

.arrow-prev {
	left: -24px;
	background-color: #ffffff !important;
	transition: all 0.4s ease;
	box-shadow: 3px 3px 6px -1px rgba(0, 111, 255, 0.03),
		1px 1px 3px 0px rgba(0, 111, 255, 0.01),
		-2px -2px 5px 0px rgba(0, 111, 255, 0.04) !important;
	transform: scale(1);
}

.arrow-next {
	right: 32px;
	background-color: #ffffff !important;
	transition: all 0.4s ease !important;
	box-shadow: 3px 3px 6px -1px rgba(0, 111, 255, 0.03),
		1px 1px 3px 0px rgba(0, 111, 255, 0.01),
		-2px -2px 5px 0px rgba(0, 111, 255, 0.04) !important;
	transform: scale(1) !important;
}

.scroll-menu-arrow--disabled {
	transition: all 0.2s ease-in-out;
	opacity: 0;
	cursor: default;
	button {
		cursor: default;
	}
}

.MuiBottomNavigationAction-root {
	min-width: 70px !important;
}

.MuiBottomNavigationAction-label {
	color: #6b98d9;
}

.button-icon {
	padding: 1rem;
}

.quotetext {
	font-family: bookmania, serif !important;
	quotes: '“' '”';
}

.quotetext:before {
	content: open-quote;
}

.quotetext:after {
	content: close-quote;
}

.line-clamp-1 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.line-clamp-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.line-clamp-3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.line-clamp-4 {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.articleIL {
	// p:first-of-type:nth-child(1) {
	//   color: #6bcfd9;
	//   float: left;
	//   font-family: Georgia;
	//   font-size: 90px;
	//   line-height: 60px;
	//   padding-top: 8px;
	//   padding-right: 3px;
	//   padding-left: 3px;
	// }
	a {
		color: #6b98d9;
	}
	ol,
	ul {
		padding-bottom: 0.5rem;
		li {
			line-height: 1.65rem;
			padding-bottom: 0.5rem;
			font-size: 1.05rem;
		}
	}
}

.gradient-text {
	background-image: -webkit-linear-gradient(
		70deg,
		#717271,
		#838383,
		#fafbfb,
		#fafbfb,
		#717271,
		#666666
	);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	position: relative;
}

.accordion-icon {
	border-radius: 32px;
	padding: 12px;
}

.gradient-text:after {
	background: none;
	content: attr(data-text);
	left: 0;
	right: 0;
	top: 0;
	position: absolute;
	text-shadow: 3px 3px 4px rgba(000, 000, 000, 0.5);
	z-index: -1;
}

.websembodytext {
	p {
		span {
			font-size: 1.05rem !important;
		}
	}
}

.slick-list {
	display: flex;
	.slick-track {
		display: flex;
	}
}

.seminar-slider .slick-dots {
	margin-bottom: 40px !important;
}

.seminar-slider .slick-arrow {
	width: 48px;
	height: 48px;
	background-color: #6b98d9;
	border-radius: 24px;
	z-index: 99;
}

.seminar-slider .slick-arrow:hover {
	background-color: #002a54;
}

.seminar-slider .slick-arrow:focus {
	background-color: #002a54;
}

.seminar-slider .slick-arrow.slick-prev {
	left: -72px;
}

.seminar-slider .slick-arrow.slick-next {
	right: -72px;
}

.slick-dots li {
	width: 40px;
	height: 40px;
}

.slick-dots li button:before {
	transform: scale(2);
	color: #546b9d !important;
}

.congress-quote {
	blockquote {
		background: #f9f9f9;
		border-left: 10px solid #4c4a89;
		margin: 1.5em 10px;
		padding: 0.5em 10px;
	}
	blockquote p {
		display: inline;
	}
}

//START: Collapsable Sections
.accordion {
	width: 100%;
	overflow: hidden;
}

.accordion section {
	width: 100%;
	float: left;
	overflow: hidden;
	cursor: pointer;
	h1 {
		a::after {
			content: '▼';
			float: right;
			font-size: 16px;
			padding-top: 12px;
			padding-right: 16px;
		}
	}
	h1.notable {
		border: 2px solid #002a54;
		padding: 8px;
		border-radius: 8px;
	}
}
.accordion section:hover {
	h1 {
		a {
			color: #6b98d9 !important;
		}
	}
}

.accordion section p {
	display: none;
}

.accordion section h2 {
	display: none;
}

.accordion section h3 {
	display: none;
}

.accordion section ul {
	display: none;
}

.accordion section:after {
	position: relative;
	font-size: 24px;
	font-weight: bold;
}
.accordion section:target h1 {
	width: 100%;
}

.accordion section:target h1 a {
	padding: 0;
}

.accordion section:target h1 a::after {
	content: '▲';
}

.accordion section:target p {
	display: block;
}

.accordion section:target h2 {
	display: block;
}

.accordion section:target h3 {
	display: block;
}

.accordion section:target ul {
	display: block;
}

.accordion section h1 a {
	display: block;
	font-weight: normal;
	text-decoration: none;
}

// .pbody {
//   p {
//     text-align: justify;
//     img {
//       text-align: center;
//     }
//     span {
//       text-align: center;
//     }
//     b {
//       span {
//         text-align: center !important;
//       }
//     }
//   }
//   ul {
//     li {
//       text-align: justify;
//       img {
//         text-align: center;
//       }
//     }
//   }
// }

//END: Collapsable Sections
.bodyhtmlraw {
	h1 {
		font-size: 2rem;
		font-weight: 400 !important;
		line-height: 1.2;
		margin-bottom: 1rem;
		padding-top: 0.75rem;
	}
	h2 {
		font-size: 1.75rem;
		font-weight: 400;
		line-height: 1.167;
		margin-bottom: 0.85rem;
		padding-top: 0.75rem;
	}
	h3 {
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 1.235;
		margin-bottom: 0.85rem;
		padding-top: 0.75rem;
	}
	h4 {
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 1.334;
		margin-bottom: 0.75rem;
		padding-top: 0.65rem;
	}
	h5 {
		font-size: 1.15rem;
		font-weight: 600;
		line-height: 1.6;
		margin-bottom: 0.75rem;
		padding-top: 0.5rem;
	}
	h6 {
		font-size: 1rem;
		font-weight: 700;
		line-height: 1.6;
		margin-bottom: 0.75rem;
		padding-top: 0.5rem;
	}
	p {
		font-size: 1.05rem;
		font-weight: 400;
		line-height: 1.5;
		margin-bottom: 0.75rem;
	}
	ol,
	ul {
		padding-bottom: 0.5rem;
		padding-top: 8px;
		li {
			line-height: 1.65rem;
			padding-bottom: 0.5rem;
			font-size: 1.05rem;
		}
		ul {
			padding-bottom: initial !important;
			padding-top: initial !important;
			li {
				padding-bottom: 0 !important;
			}
		}
	}
	a {
		color: #6b98d9;
	}
	img {
		padding: 8px 0;
	}
	blockquote {
		text-align: center;
		margin: 1.5em 10px;
		padding: 2em 0;
		quotes: '\201C''\201D''\2018''\2019';
		color: #5f7fa0;
	}
	blockquote:before {
		color: #ccc;
		content: open-quote;
		font-size: 4em;
		line-height: 0.1em;
		margin-right: 0.25em;
		vertical-align: -0.4em;
		float: left;
	}
	blockquote::after {
		color: #ccc;
		content: close-quote;
		font-size: 4em;
		// line-height: 0.1em;
		// margin-left: 0.25em;
		vertical-align: -0.4em;
		float: right;
	}
	blockquote p {
		display: inline;
		font-size: larger;
		font-weight: 700;
	}
	blockquote i {
		display: inline;
		font-size: larger;
		font-weight: 700;
	}
	blockquote em {
		display: inline;
		font-size: larger;
		font-weight: 700;
		padding-right: 16px;
	}
	sup {
		vertical-align: top;
		position: relative;
		top: -0.2em;
		font-size: 13px;
		display: inline-block !important;
		text-decoration: none !important;
		sup {
			vertical-align: initial !important;
			position: initial !important;
			top: initial !important;
			font-size: 13px !important;
			display: inline-block !important;
			text-decoration: none !important;
		}
	}
	sub {
		display: inline-block !important;
		text-decoration: none !important;
	}
	img {
		max-width: 100% !important;
		height: auto;
		text-align: center;
	}
	table {
		width: 100% !important;
		border-color: #d9d9d9 !important;
		border-style: solid;
		tbody {
			tr {
				td {
					border-color: #e8e8e8;
					border-style: solid !important;
					border-width: 1;
					padding: 12px;
					h1 {
						font-size: 1.65rem;
						margin: 6px 0;
						b {
							font-weight: 600;
						}
					}
					h2 {
						font-size: 1.35rem;
						strong,
						b {
							font-weight: 400 !important;
						}
					}
					p {
						margin: 0;
					}
					ul {
						line-height: 1.5;
						font-size: 14px;
					}
				}
			}
		}
	}
}

.tradeactivationbodyraw p {
	background-color: transparent !important;
}

.listitems-slider-styles {
	padding: 8px 16px;
	color: white;
}

.page-header-width {
	width: 65%;
}

.two-column-layout {
	column-count: 2;
	column-gap: 80px;
	line-height: 1.5;
}

//SUSPENSE SPINNER

.circular-progress-spinner--graphic {
	height: 75px;
	position: relative;
	width: auto;
}

//CONGRESS 2022 INVESTMENT TABLE

.tg {
	border-collapse: collapse;
	border-spacing: 0;
	color: #966a4d;
}

.tg td {
	border-color: #875f45;
	border-style: solid;
	border-width: 1px;
	overflow: hidden;
	padding: 16px 24px;
	word-break: normal;
}

.tg th {
	border-color: #875f45;
	border-style: solid;
	border-width: 1px;
	overflow: hidden;
	padding: 12px 18px;
	word-break: normal;
}

.tg .tg-nrix {
	text-align: center;
	vertical-align: middle;
	padding: 12px 40px;
}

.tg .tg-0lax {
	text-align: left;
	vertical-align: top;
}

.tg-bg {
	background-color: #966a4d;
	color: #ffffff !important;
}

.register-button {
	height: 100%;
	border: 0;
	border-radius: 4;
	padding: 0 24px;
	background-color: #6b98d9;
	color: #ffffff;
	transition: all 0.2s;
}

.register-button:hover {
	transition: all 0.2s;
	background-color: #567aae;
	cursor: pointer;
}

//CONGRESS 2022 ACCORDION ALIGNMENT
.MuiAccordionSummary-content {
	align-items: center;
	img {
		margin-right: 16px;
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.circular-progress-rotating {
	animation: rotating 2s linear infinite;
}
.circular-progress-spinner--message {
	text-align: center;
}

.circular-progress-spinner--message2 {
	text-align: center;
	font-size: 12px;
	color: lightgrey;
}

.print-only {
	display: none;
}

.update-article-cards {
	flex: 0 1 25%;
}

.MuiBackdrop-root {
	background-color: rgba(0, 42, 84, 0.5) !important;
}

@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}
	.print-width-50 {
		max-width: 50% !important;
	}

	.print-reset-height {
		height: 100px !important;
	}

	.print-only {
		display: block;
	}
}

.image-hide-sm {
	display: none;
}

@media (min-width: 600px) {
	.MuiTab-root {
		min-width: 120px !important;
	}
}

@media only screen and (max-width: 1198px) {
	.scroll-menu-arrow {
		display: none;
	}
}
@media only screen and (max-width: 959px) {
	.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 {
		margin-left: 0;
		width: 100%;
	}

	.update-article-cards {
		flex: 0 1 33%;
	}

	.secondstimer {
		display: none;
	}

	.bodyhtmlraw {
		h1 {
			font-size: 2rem;
		}
		h2 {
			font-size: 1.65rem;
			font-weight: 400;
			line-height: 1.167;
			margin-bottom: 0.35em;
		}
		h3 {
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.235;
			margin-bottom: 0.35em;
		}
		h4 {
			font-size: 1.15rem;
			font-weight: 600;
			line-height: 1.334;
			margin-bottom: 0.35em;
		}
		img {
			max-width: 100%;
			height: auto;
			margin: 12px 0;
		}
		table {
			width: 100%;
		}
	}

	.two-column-layout {
		column-count: 1;
		column-gap: 0;
	}

	.page-header-width {
		width: 100%;
	}
}

@media only screen and (min-width: 395.5px) and (max-width: 595.5px) {
	.update-article-cards {
		flex: 0 1 50%;
	}
}

@media only screen and (max-width: 415px) {
	.image-hide-lg {
		display: none;
	}

	.image-hide-sm {
		display: inherit;
	}

	.MuiBottomNavigationAction-label {
		display: none;
	}

	.update-article-cards {
		flex: 0 1 100%;
	}
}

// Crafter Studio ICE control tweaking
i.studio-ice-indicator {
	width: 24px;
	height: 26px;
	font-size: 20px;
	border-radius: 8px;
}
div.studio-ice-overlay {
	border-width: 0.25px;
	border-radius: 5px;
	border-color: rgba(246, 204, 47, 0.85);
	box-shadow: 0 0 5px 2px rgba(246, 204, 47, 0.85),
		inset 0 0 8px 3px rgba(246, 204, 47, 0.85);
}
